import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetFinalInvestmentDateCalculatorQuery
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/application/queries/get-final-investment-date-calculator-query';
import UpdatePlanRetirementFundInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/update-plan-retirement-fund-investor-goal-command';
import EditRetirementFundGoalPlanRetirementAgeService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-retirement-age-service';
import EditRetirementFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-amount-service';
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-define-by-service';

// Domain
import { FinalInvestmentDateCalculatorDto }
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/domain/dtos/final-investment-date-calculator-dto';
import { UpdatePlanRetirementFundDto }
  from '@/modules/flagship/retirement-investor-goal/domain/dtos/update-plan-retirement-fund-dto';
import { StateManager }
  from '@/modules/my-investment/allianz/allianz-account/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditRetirementFundNewFundViewModel {
  @Inject(TYPES.GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
  private readonly get_final_investment_date_query!: GetFinalInvestmentDateCalculatorQuery;

  @Inject(TYPES.UPDATE_PLAN_RETIREMENT_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_plan_retirement_fund_command!:
    UpdatePlanRetirementFundInvestorGoalCommand;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_RETIREMENT_AGE_SERVICE)
  private readonly retirement_age_service!: EditRetirementFundGoalPlanRetirementAgeService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditRetirementFundGoalPlanCurrentAmountService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditRetirementFundGoalPlanDefineByService;

  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  private readonly allianz_account_state_manager!: StateManager;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.new_fund';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  plan_information = [
    {
      label: this.translate('target_amount'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('current_amount'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('monthly_contribution'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('monthly_pension'),
      current_plan: '',
      new_plan: '',
    },
  ];

  update_plan_dto: UpdatePlanRetirementFundDto = {
    retirement_fund_investor_goal: '',
    defined_by: '',
    issued_age: 0,
    retirement_age_adjusted: 0,
    retirement_range_adjusted: 0,
    pension_range_adjusted: 0,
    monthly_pension_adjusted: 0,
    modify_recurring_contribution_automatically: false,
    investor_goal: {
      initial_amount: 0,
      initial_amount_adjusted: 0,
      accumulated_amount: 0,
      monthly_required_amount: 0,
      final_investment_date: '',
      initial_investment_date: '',
    },
  }

  initial_investment_date = '';

  final_investment_date = '';

  is_loading = false;

  is_checkbox_active = false;

  home_desire = false;

  initialize = async () => {
    this.loadRecurrentContributionInformation();
    await this.setInitialValues();
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getBorderClass(index: number) {
    return (index < 3) ? '' : 'rounded-br-lg';
  }

  getRetirementAgeInformation = () => (this.retirement_age_service.getRetirementAgeInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  isIndexLessThanZero(index: number) {
    return index > 0;
  }

  setInitialValues = async () => {
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    const define_by = this.getDefineByInformation();
    const retirement_age = this.getRetirementAgeInformation();
    const amount_to_reach_goal = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.contribution : define_by.by_pension.you_must_save;
    const desired_monthly_pension = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.you_must_save : define_by.by_pension.contribution;
    const retirement_range_adjusted = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.retirement_range_adjusted : define_by.by_pension.retirement_range_adjusted;
    let initial_amount = current_amount.additional_amount;
    let initial_amount_adjusted = this.parseCurrencyToNumber(current_plan.current_amount);
    if (current_amount.there_is_more_savings === 'yes') {
      initial_amount_adjusted += current_amount.amount_added;
    } else initial_amount = 0;
    const new_target_amount = (define_by.is_defined_by_contribution) ? define_by.by_contribution
      .accumulated_amount : define_by.by_pension.accumulated_amount;
    this.plan_information[0].current_plan = current_plan.target_amount;
    this.plan_information[0].new_plan = this.getAmountFormatted(new_target_amount);
    this.plan_information[1].current_plan = current_plan.current_amount;
    this.plan_information[1].new_plan = this.getAmountFormatted(initial_amount_adjusted);
    this.plan_information[2].current_plan = current_plan.monthly_required_amount;
    this.plan_information[2].new_plan = this.getAmountFormatted(amount_to_reach_goal);
    [this.plan_information[3].current_plan] = current_plan.monthly_pension.split('mensuales');
    this.plan_information[3].new_plan = this.getAmountFormatted(desired_monthly_pension);
    await this.loadNewFinalInvestmentDate(retirement_range_adjusted);
    this.update_plan_dto = {
      retirement_fund_investor_goal: current_plan.retirement_fund_id,
      defined_by: (define_by.is_defined_by_contribution) ? 'contribution' : 'pension',
      issued_age: retirement_age.current_age,
      retirement_age_adjusted: retirement_age.retirement_age,
      retirement_range_adjusted,
      pension_range_adjusted: define_by.pension_range_adjusted,
      monthly_pension_adjusted: (define_by.is_defined_by_contribution) ? define_by.by_contribution
        .you_must_save : define_by.by_pension.contribution,
      modify_recurring_contribution_automatically: this.is_checkbox_active,
      investor_goal: {
        initial_amount,
        initial_amount_adjusted,
        accumulated_amount: new_target_amount,
        monthly_required_amount: amount_to_reach_goal,
        final_investment_date: this.final_investment_date,
        initial_investment_date: this.initial_investment_date,
      },
    };
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  loadNewFinalInvestmentDate = async (retirement_range_adjusted: number) => {
    try {
      this.initial_investment_date = this.datetime_value.create().toString();
      const payload: FinalInvestmentDateCalculatorDto = {
        initial_investment_date: this.initial_investment_date,
        period_in_months: retirement_range_adjusted,
      };
      const { final_investment_date } = await this.get_final_investment_date_query.execute(payload);
      this.final_investment_date = final_investment_date;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_final_investment_date_query'));
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  updateNewRetirementFund = async () => {
    try {
      this.is_loading = true;
      this.update_plan_dto.modify_recurring_contribution_automatically = this.is_checkbox_active;
      await this.update_plan_retirement_fund_command.execute(this.update_plan_dto);
      this.view.$emit('nextStep');
      this.is_loading = false;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_plan_retirement_fund_command'));
    }
  }

  loadRecurrentContributionInformation = () => {
    const { home_desire } = this.allianz_account_state_manager.state.account;
    this.home_desire = home_desire;
  }
}
